<template>
  <div>
    <div class="vx-row mb-6">
      <h3>{{ title }}</h3>
    </div>
    <!-- Tree betulan -->
    <div class="vx-row my-4">
      <div class="vx-col sm:w-1/1 w-full mb-base">

        <!-- vue-tree-list -->
        <!-- <button @click="addNode">Add Node</button> -->
        <vue-tree-list
          @click="onClick"
          @change-name="onChangeName"
          @delete-node="onDel"
          @add-node="onAddNode"
          :model="data"
          default-tree-node-name="new node"
          default-leaf-node-name="new leaf"
          v-bind:default-expanded="true"
        >
          <template v-slot:leafNameDisplay="slotProps">
            <span v-if="slotProps.model.pid != 0" style="font-size: 14px;">
              {{ slotProps.model.code_external + " - " + slotProps.model.name + " [ "+ slotProps.model.wp +" ] " }}
            </span>
            <span v-else style="font-size: 14px;">
              {{ slotProps.model.name }}
            </span>
          </template>
          <span class="icon" slot="addTreeNodeIcon">📂</span>
          <span class="icon" slot="addLeafNodeIcon">＋</span>
          <span class="icon" slot="editNodeIcon">📃</span>
          <span class="icon" slot="delNodeIcon">✂️</span>
          <span class="icon" slot="leafNodeIcon">📍</span>
          <span class="icon" slot="treeNodeIcon">🔻</span>
        </vue-tree-list>
        <!-- <button @click="getNewTree">Get new tree</button>
        <pre>
          {{newTree}}
        </pre> -->
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list'

export default {
  components: {
    VueTreeList
  },
  props: ["id_workteam","source","dataWorkTeam"],
  data() {
    return {
      title: "Work Team Tree View",
      dataTree: [
        {
          name: '-',
          id: 0,
          children: [
            {
              name: '-',
              id: 3,
              children: [
                {
                  name: '=',
                  id: 4,
                  children: []
                }
              ]
            },
            {
              name: 'Node 1-2',
              id: 13,
              children: []
            }
          ]
        },
        {
          name: 'Node 0-1',
          id: 14,
          children: []
        }
      ],
      // vue-tree-list
      newTree: {},
      data: new Tree([
        {
          name: 'Node 1',
          id: 1,
          pid: 0,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
          children: [
            {
              name: 'Node 1-2',
              id: 2,
              isLeaf: true,
              pid: 1
            }
          ]
        },
        {
          name: 'Node 2',
          id: 3,
          pid: 0,
          disabled: true
        },
        {
          name: 'Node 3',
          id: 4,
          pid: 0
        }
      ]),
    };
  },
  mounted() {
    console.log("Disini lho")
    console.log("this.dataWorkTeam", this.dataWorkTeam)
    if(this.source == "form"){
      // this.setData(this.dataWorkTeam);
      this.setDataVTL(this.dataWorkTeam);
    } else if ( this.source == "index" ) {
      console.log("dari index lho! idnya?", this.id_workteam)
      this.getData();
    }
  },
  methods: {
    addItem: function() {
      console.log("addItem()");
    },
    getData () {
    this.$http
        .get('/api/sfa/v1/work-team/by-id', {
          params: {
            company_id: this.$store.state.user.currentUser.company_id,
            work_team_id: this.id_workteam
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            this.dataWorkTeam = [];
            if (resp.data.leader.length > 0) {
              for (let i = 0; i < resp.data.leader.length; i++) {
                resp.data.leader[i].is_leader = true;
                this.dataWorkTeam.push(resp.data.leader[i]);
              }
            }
            
            if (resp.data.members.length > 0) {
              for (let i = 0; i < resp.data.members.length; i++) {
                resp.data.members[i].is_leader = false;
                this.dataWorkTeam.push(resp.data.members[i]);
              }
            }

            console.log("after getData", this.dataWorkTeam)
            console.log("this.dataWorkTeam", this.dataWorkTeam)
            
            this.dataWorkTeam = {
              team_name: resp.data.headers.name,
              members: this.dataWorkTeam
            }

            this.setDataVTL(this.dataWorkTeam);
          } else {
            // console.log(resp)
            this.$vs.notify({
              title: 'Error',
              text: resp.message,
              color: 'danger',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle'
            })
          }
        })
    },
    setDataVTL(data){
      var data_ = new Tree([
        {
          name: 'Node 1',
          id: 1,
          pid: 0,
          dragDisabled: true,
          addTreeNodeDisabled: true,
          addLeafNodeDisabled: true,
          editNodeDisabled: true,
          delNodeDisabled: true,
          children: [
            {
              name: 'Node 1-2',
              id: 2,
              isLeaf: true,
              pid: 1
            }
          ]
        },
      ]);

      console.log("masuk setDataVTL lho")
      console.log("iki datane ", data)
      let prepared_data = null, temp_member = [];
      this.data = [];
      // Setup init leader node
      data.members.forEach(dw => {
        let wp = this.source == "form" ? dw.work_position.name : dw.work_position;
        if(dw.is_leader) {
          prepared_data = {
            name: data.team_name,
            id: dw.id,
            pid: 0,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
            children: [
              {
                name: dw.name,
                code_external: dw.code_external,
                wp: wp,
                id: dw.id,
                isLeaf: false,
                pid: 0,
                dragDisabled: true,
                addTreeNodeDisabled: true,
                addLeafNodeDisabled: true,
                editNodeDisabled: true,
                delNodeDisabled: true,
                children: [{}],
              }
            ]
          }
        }
      });
      console.log(prepared_data)

      // isi member node
      data.members.forEach(dw => {
        let wp = this.source == "form" ? dw.work_position.name : dw.work_position;
        if(!dw.is_leader) {
          let temp = {
            name: dw.name,
            code_external: dw.code_external,
            wp: wp,
            id: dw.id,
            isLeaf: true,
            pid: prepared_data.id,
            dragDisabled: true,
            addTreeNodeDisabled: true,
            addLeafNodeDisabled: true,
            editNodeDisabled: true,
            delNodeDisabled: true,
            children: [],
          }
          temp_member.push(temp)
        }
      });

      prepared_data.children[0].children = temp_member;

      console.log(temp_member)

      this.data = new Tree([prepared_data]);
    },
    onDel(node) {
      console.log(node)
      node.remove()
    },
    onChangeName(params) {
      console.log(params)
    },

    onAddNode(params) {
      console.log(params)
    },

    onClick(params) {
      console.log(params)
    },

    addNode() {
      var node = new TreeNode({ name: 'new node', isLeaf: false })
      if (!this.data.children) this.data.children = []
      this.data.addChildren(node)
    },

    getNewTree() {
      var vm = this
      function _dfs(oldNode) {
        var newNode = {}

        for (var k in oldNode) {
          if (k !== 'children' && k !== 'parent') {
            newNode[k] = oldNode[k]
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          newNode.children = []
          for (var i = 0, len = oldNode.children.length; i < len; i++) {
            newNode.children.push(_dfs(oldNode.children[i]))
          }
        }
        return newNode
      }

      vm.newTree = _dfs(vm.data)
    }
  },
  filters: {
    
  },
  watch: {
    
  },
  computed: {
    
  },
};
</script>
